	/*
  	Flaticon icon font: Flaticon
  	Creation date: 02/03/2021 10:22
  	*/

    @font-face {
      font-family: "Flaticon";
      src: url("../fonts/Flaticon.eot");
      src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
           url("../fonts/Flaticon.woff2") format("woff2"),
           url("../fonts/Flaticon.woff") format("woff"),
           url("../fonts/Flaticon.ttf") format("truetype"),
           url("../fonts/Flaticon.svg#Flaticon") format("svg");
      font-weight: normal;
      font-style: normal;
    }
    
    @media screen and (-webkit-min-device-pixel-ratio:0) {
      @font-face {
        font-family: "Flaticon";
        src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
      }
    }
    
    [class^="flaticon-"]:before, [class*=" flaticon-"]:before,
    [class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
      font-family: Flaticon;
      font-style: normal;
    }
    .flaticon-right-arrow:before { content: "\f118"; }
    .flaticon-left-arrow:before { content: "\f119"; }
    .flaticon-arrow-down-sign-to-navigate:before { content: "\f11a"; }
    .flaticon-right-arrow-1:before { content: "\f11b"; }
    .flaticon-phone-call:before { content: "\f11c"; }
    .flaticon-phone:before { content: "\f11d"; }
    .flaticon-email:before { content: "\f11e"; }
    .flaticon-email-1:before { content: "\f11f"; }
    .flaticon-envelope:before { content: "\f120"; }
    .flaticon-envelope-1:before { content: "\f121"; }
    .flaticon-globe:before { content: "\f122"; }
    .flaticon-maps-and-flags:before { content: "\f123"; }
    .flaticon-placeholder:before { content: "\f124"; }
    .flaticon-pin:before { content: "\f125"; }
    .flaticon-avatar:before { content: "\f126"; }
    .flaticon-user-profile:before { content: "\f127"; }
    .flaticon-user:before { content: "\f128"; }
    .flaticon-user-1:before { content: "\f129"; }
    .flaticon-calendar:before { content: "\f12a"; }
    .flaticon-calendar-1:before { content: "\f12b"; }
    .flaticon-clock:before { content: "\f12c"; }
    .flaticon-clock-1:before { content: "\f12d"; }
    .flaticon-bell:before { content: "\f12e"; }
    .flaticon-notification:before { content: "\f12f"; }
    .flaticon-loupe:before { content: "\f130"; }
    .flaticon-search:before { content: "\f131"; }
    .flaticon-facebook:before { content: "\f132"; }
    .flaticon-twitter:before { content: "\f133"; }
    .flaticon-instagram:before { content: "\f134"; }
    .flaticon-instagram-1:before { content: "\f135"; }
    .flaticon-youtube:before { content: "\f136"; }
    .flaticon-youtube-1:before { content: "\f137"; }
    .flaticon-checked:before { content: "\f138"; }
    .flaticon-check-mark:before { content: "\f139"; }
    .flaticon-comment:before { content: "\f13a"; }
    .flaticon-checked-1:before { content: "\f13b"; }
    .flaticon-menu:before { content: "\f13c"; }
    .flaticon-linkedin:before { content: "\f13d"; }
