@import url('https://fonts.googleapis.com/css2?family=ABeeZee&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@200;300;400;500;600;700;800;900&display=swap');
@font-face {
  font-family: 'Big Caslon';
  src: url('../fonts/BigCaslon-Medium.eot');
  src: url('../fonts/BigCaslon-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BigCaslon-Medium.ttf') format('truetype'),
    url('../fonts/BigCaslon-Medium.woff') format('woff'),
    url('../fonts/BigCaslon-Medium.woff2') format('woff2'),
    url('../fonts/BigCaslon-Medium.svg#BigCaslon-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
html {
  font-size: 62.5%;
  height: 100%;
  background-color: #000000;
}
body {
  font-family: 'Public Sans', sans-serif;
  font-weight: 300;
  font-style: normal;
  box-sizing: border-box;
  position: relative;
  font-size: 1.4rem;
  line-height: 24px;
  background-color: #000000;
}
a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: 0;
}
input,
input:focus,
input:hover,
input:active {
  outline: 0;
  box-shadow: none;
}
ul {
  margin: 0 auto;
  padding: 0;
  list-style: none;
}

p {
  white-space: pre-wrap;
}

#nav-icon {
  position: relative;
  width: 30px;
  height: 18px;
  cursor: pointer;
  z-index: 98;
  display: none;
}
#nav-icon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 50%;
  background: #ffffff;
  opacity: 1;
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -o-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#nav-icon span:nth-child(even) {
  left: 50%;
}
#nav-icon span:nth-child(odd) {
  left: 0;
}
#nav-icon span:nth-child(1),
#nav-icon span:nth-child(2) {
  top: 0;
}
#nav-icon span:nth-child(3),
#nav-icon span:nth-child(4) {
  top: 6px;
}
#nav-icon span:nth-child(5),
#nav-icon span:nth-child(6) {
  top: 12px;
}
#nav-icon span:nth-child(7),
#nav-icon span:nth-child(8) {
  top: 18px;
}
#nav-icon.open span:nth-child(1),
#nav-icon.open span:nth-child(8) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
#nav-icon.open span:nth-child(2),
#nav-icon.open span:nth-child(7) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
#nav-icon.open span:nth-child(1) {
  left: 1px;
  top: 2px;
}
#nav-icon.open span:nth-child(2) {
  left: calc(50% - 6px);
  top: 2px;
}
#nav-icon.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}
#nav-icon.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}
#nav-icon.open span:nth-child(5) {
  left: -50%;
  opacity: 0;
}
#nav-icon.open span:nth-child(6) {
  left: 100%;
  opacity: 0;
}
#nav-icon.open span:nth-child(7) {
  left: 1px;
  top: 9px;
}
#nav-icon.open span:nth-child(8) {
  left: calc(50% - 6px);
  top: 9px;
}

.border-bottom {
  border-bottom: 1px solid #3c3c3c !important;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  padding: 20px;
}

ul.navList {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: center;
}
ul.navList li {
  margin: 0 15px;
}
ul.navList li:last-child {
  margin-right: 0;
}
ul.navList li a {
  color: #ffffff;
  font-size: 1.1rem;
  text-transform: uppercase;
  letter-spacing: 0.6px;
  font-weight: 500;
}
ul.navList li.getAppBtn a {
  color: #262526;
  background-color: #ffffff;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  padding: 10px 15px;
}
.bannerWrap {
  background-image: url(../img/banner.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 70vh;
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}
.bannerWrap:after {
  content: '';
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
}
.bannnerTitlWrap {
  position: relative;
  z-index: 9;
  text-align: center;
}
.bannnerTitlWrap p {
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0.6px;
  margin: 0 auto;
}
.bannnerTitlWrap h1 {
  font-family: 'Big Caslon';
  margin: 0 auto;
  font-size: 3.8rem;
  line-height: 38px;
}
.tabNavWrapper {
  background-color: #000000;
  padding: 50px 0;
}
.nav-tabs {
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-tabs li {
  margin: 0 15px;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: 0;
  border-radius: 5px;
  background-color: #ffffff;
  color: #262526;
}
.nav-tabs li a {
  border-radius: 5px;
  border: 1px solid #ffffff !important;
  padding: 6px 15px;
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: 500;
  letter-spacing: 0.4px;
  color: #ffffff;
  min-width: 110px;
  text-align: center;
}
.tab-content {
  padding-top: 30px;
}
.tab-content .imgWrapper {
  padding: 30px;
}
.tab-content .dataWrapper {
  padding: 30px 10px;
}
.tab-content .dataWrapper h6 {
  color: #ffffff;
  font-size: 1.1rem;
  text-transform: uppercase;
  letter-spacing: 0.6px;
  font-weight: 500;
}
.tab-content .dataWrapper h2 {
  font-family: 'Big Caslon';
  font-size: 2.8rem;
  margin: 10px 0;
}
.tab-content .dataWrapper p {
  color: #8b8b8b;
  letter-spacing: 0.2px;
  margin: 0;
}

.accountRemoveWrap {
  background-color: #000000;
  height: 100vh;
  position: relative;
  padding: 20px 10px;
}
.accountRemoveWrap .logoWrapper {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.accountRemoveWrap .logoWrapper a {
  letter-spacing: 0.02em;
  color: #ffffff;
  font-family: 'Big Caslon';
  font-size: 1.8rem;
}
.accountRemoveWrap .middleDataWrap {
  height: 96vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}
.accountRemoveWrap .linksWrap ul {
  display: flex;
  align-items: center;
  justify-content: center;
}
.accountRemoveWrap .linksWrap ul li {
  width: 44px;
  height: 44px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 0 5px;
}
.accountRemoveWrap .linksWrap ul li a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 2rem;
  flex-flow: row wrap;
}
.accountRemoveWrap .linksWrap ul li.logoLink {
  border: 1px solid #ffffff;
}
.accountRemoveWrap .linksWrap ul li.logoLink a {
  font-family: 'Big Caslon';
}
.accountRemoveWrap .linksWrap ul li.facebookLink {
  background-color: #1877f2;
}
.accountRemoveWrap .middleDataWrap .dataWrap {
  margin: 15px auto;
  text-align: center;
}
.accountRemoveWrap .middleDataWrap .dataWrap h2 {
  font-family: 'Big Caslon';
  color: #ffffff;
  font-size: 2.4rem;
}
.accountRemoveWrap .middleDataWrap .dataWrap p {
  color: #8b8b8b;
  margin: 0 auto;
  font-size: 1.2rem;
  letter-spacing: 0.2px;
}
.accountRemoveWrap .middleDataWrap .cancelCtaWrap {
  margin: 30px auto 0;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}

.accountRemoveWrap .middleDataWrap .cancelCtaWrap a {
  color: #262526;
  background-color: #ffffff;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0.2px;
  padding: 8px 15px;
  border-radius: 8px;
}

/*New css 0212*/
.infoDtlPge {
  position: relative;
}
.infoDtlWrap {
  background-color: #000000;
  width: 100%;
  padding: 0 0 40px;
  z-index: 99;
}
.userInfoImg {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  border: 2px solid #ffffff;
  overflow: hidden;
  margin: 20px auto 0;
}
.userInfoName {
  padding: 30px 0 0;
  text-align: center;
}
.userInfoName h2 {
  font-family: 'Big Caslon';
  color: #ffffff;
  font-size: 2.8rem;
  margin: 0 0 2px;
  letter-spacing: 1px;
}
.userInfoName p {
  color: #acacac;
  margin: 10px auto 0;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 18px;
}
.userInfoName p a {
  color: #ffffff;
  text-decoration: underline;
  font-weight: 600;
}
.infoMdlDtl {
  padding: 30px 0;
}
.infoMdlDtl .audioListing li {
  border-top: 1px solid #3c3c3c;
  padding: 13px 0 13px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}
.infoMdlDtl .audioListing li:last-child {
  padding: 15px 0 0;
}
.infoMdlDtl .audioListing li .leftPanel {
  width: calc(100% - 115px);
  display: flex;
  flex-direction: row;
  align-items: center;
}
.infoMdlDtl .audioListing li .leftPanel a {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
.infoMdlDtl .audioListing li .dataWrap {
  padding-left: 10px;
}
.infoMdlDtl .audioListing li .dataWrap h2 {
  font-size: 1.4rem;
  font-weight: 600;
  margin: 0;
  color: #cccccc;
}
.infoMdlDtl .audioListing li .dataWrap p {
  color: #acacac;
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0;
  line-height: 18px;
}
.footerWrap {
  background-color: #262626;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 20px 0;
  align-items: center;
}
.infoBtmDtl {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}
.infoBtmDtl .leftDataWrap {
  display: flex;
  flex-flow: row wrap;
  width: calc(100% - 65%);
  align-items: center;
}
.infoBtmDtl .leftDataWrap .imgWrap {
  width: 60px;
  margin-right: 15px;
}
.infoBtmDtl .leftDataWrap .dataWrap {
  width: calc(100% - 75px);
}
.infoBtmDtl .leftDataWrap .dataWrap p {
  margin: 0;
  color: #acacac;
  font-weight: 500;
  line-height: 20px;
}
.infoBtmDtl .leftDataWrap .dataWrap p span {
  color: #ffffff;
}

.userImgWrap {
  margin: 0 auto;
  text-align: center;
  background-image: url(../img/avatarCircle.svg);
  background-repeat: no-repeat;
  padding: 15px;
  position: relative;
  display: inline-block;
  margin: 0 auto;
  text-align: center;
}
.userImgWrap img.user-img {
  border: 2px solid #fff !important;
  border-radius: 50%;
}
.userImgWrap .btnPlay {
  cursor: default !important;
  position: absolute;
  background-color: #ffffff;
  border: 0;
  box-shadow: none;
  border-radius: 6px;
  height: 30px;
  width: 30px;
  margin: 0 auto;
  text-align: center;
  right: 8px;
  top: 18px;
}

.playIconContainer {
  width: 38px;
  height: 38px;

  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 6px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.userImgWrap img.user-img {
  border: 2px solid #fff;
  border-radius: 50%;
}
.logoWrap {
  text-align: center;
  color: #fff;
  font-family: Big Caslon;
  font-size: 2rem;
  padding: 40px 0 30px;
  cursor: default;
}
.logoWrap a {
  font-family: 'Big Caslon';
  color: #ffffff;
}
.infoDtlSample .infoDtlData {
  text-align: center;
}
.infoDtlSample .userInfoName {
  padding: 20px 0 0;
}
.infoDtlSample .rightPanel .btn {
  padding: 0;
  margin: 0;
  border: 0;
  box-shadow: none;
  outline: 0;
  background-color: transparent;
  border-radius: 0;
  color: #ffffff;
  font-size: 1.1rem;
  width: 26px;
  width: 100%;
}

.default-cursor {
  cursor: default !important;
}
.infoDtlSample .ctaSample {
  text-align: center;
  padding: 35px 0 0;
}
.infoDtlSample .ctaSample .btn {
  color: #262526;
  background-color: #ffffff;
  border: 0;
  outline: 0;
  box-shadow: none;
  padding: 6px 25px;
  width: 276px;
  height: 46px;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin: 0 auto 5px;
  position: relative;
  text-transform: uppercase;
}
.infoDtlSample .ctaSample .btn:before {
  content: '+';
  color: #262526;
  font-weight: 700;
  position: absolute;
  left: 10px;
  font-size: 1.4rem;
  top: 50%;
  transform: translateY(-50%);
}
.infoDtlSample .ctaSample a {
  color: #ffffff;
  text-transform: uppercase;
  display: block;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.6px;
}
.infoDtlSampleWrap .infoDtlWrap {
  min-height: 100vh;
}
.infoDtlSampleWrap .infoDtlWrap .logoPos {
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
}
.infoDtlSampleWrap.infoDtlSample .ctaSample {
  padding: 30px 0 0;
}
.infoDtlSampleWrap.infoDtlSample .ctaSample .btn {
  margin: 0 auto;
  padding: 8px 25px;
}
.infoDtlSampleWrap.infoDtlSample .ctaSample .btn:before {
  content: none;
}
.infoDtlSampleWrap.infoDtlSample .infoDtlData {
  height: calc(100vh - 12vh);
  align-items: center;
  display: flex;
  justify-content: center;
  flex-flow: column;
}

.progress-bar {
  background-color: #575757 !important;
  height: 4px;
  border-radius: 8px;
  margin-top: 30px;
}

.play-pause-button {
  width: 26px;
  height: 26px;
  background-color: transparent;
  cursor: pointer;

  border: 2px solid #dfdfdf !important;
  border-radius: 100%;
  margin-left: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.play-pause-button:disabled {
  opacity: 0.5;
}

.track-cover {
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 6px;

  width: 38px;
  height: 38px;
}

.center {
  flex: 1;
}

.round {
  border-radius: 6px;
}

.row {
  flex-direction: row;
  align-items: center;
}

.row .space-between {
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
}

.column {
  flex: 1;
  flex-direction: row;
  align-items: center;
}

.audio-paragraph-text {
  margin-top: 8px;
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;

  color: #8b8b8b;
}

.sample-duration-group {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 40px;
}

.example-track {
  background-color: white;
}

.white-bottom {
  height: 4px;
  cursor: pointer;

  border-radius: 4px;
  background-color: white;
}

.duration-marker {
  width: 35px;
  height: 27px;
  cursor: pointer;

  background-color: white;
  position: absolute;
  bottom: 19px;
  border-radius: 2px;
  padding-top: 5px;
}

.marker {
  display: flex;
  align-items: center;
  justify-content: center;
}

.duration-marker-triangle {
  position: absolute;
  bottom: 17px;
  width: 14px;
  height: 14px;

  background: #ffffff;
  border-radius: 1px;
  transform: rotate(45deg);
  z-index: -1;
}

.slider-row {
  position: relative;
  width: 9px;
  height: 6px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
}

.slider-point {
  height: 3px;
  width: 3px;
  border-radius: 3px;

  background-color: #000000;
  z-index: 1;
}

.btn-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.upload-text {
  margin: 0;
}

.loader {
  margin-left: 20px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #555;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.infoDtlSample .form-group {
  display: block;
  margin-bottom: 0;
}
.infoDtlSample .form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.infoDtlSample .form-group label {
  position: relative;
  cursor: pointer;
}
.infoDtlSample .form-group label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #ffffff;
  box-shadow: none;
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 5px;
  width: 38px;
  height: 38px;
}
.check-circle {
  content: '';
  display: block;
  position: absolute;
  width: 21px;
  height: 21px;
  background-color: #4b4b4b;
  border-radius: 100%;
  border: 1px solid #000000;
  top: 8.5px;
  left: 8.5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.read-text {
  margin-right: 5px;
}

.choose-file {
  width: 210px;
  overflow: hidden;
  height: 0px;
  margin-top: 0px;
  transition: 0.4s;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2px;
  padding: 0px 5px;
  border-radius: 5px;
}

.__choose-file {
  padding: 0px 5px;
  overflow: hidden;
  height: 40px;
  margin-top: 0px;
  transition: 0.4s;
  border: 2px solid white;
}

.ctaSample {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px;
  margin-top: -5px;
}

.audioListing .imgWrap .btn {
  width: 40px;
  height: 40px;
}

.trimsample .trimsec {
  border-bottom: 1px solid #3c3c3c;
  padding-bottom: 15px !important;
}

.trimsample.infoDtlSample .rightPanel .btn.btn-round {
  width: 26px;
  height: 26px;
  border: 2px solid #dfdfdf !important;
  border-radius: 100% !important;
}

.trimsample.infoDtlSample .ctaSample .btn:before {
  display: none;
}

button.btn.btnPlay.btncross {
  padding: 0px;
}

.btncross .btn-red-circle {
  background: #ff543f;
  color: #fff;
  border-radius: 100%;
  padding: 2px;
  width: 18px !important;
  height: 18px !important;
  display: inline-block;
}

.btncross .btn-red-circle span {
  font-size: 10px;
  text-align: center;
  font-weight: 600;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  align-items: center;
  letter-spacing: 0.1em;
  display: inline-block;

  width: 276px;
  height: 46px;

  background-color: #ffffff;
  border-radius: 5px;
  color: #262526;
  cursor: pointer;
  padding-top: 16px;
  position: relative;
}

.loading-text {
  margin-top: 20px;
  color: #fff;
}

.disabled {
  opacity: 0.5;
}

.inputfile + label::before {
  content: '+';
  color: #262526;
  font-size: 25px;
  font-weight: 400;
  position: absolute;
  left: 25px;
  top: 14px;

  align-self: flex-start;
}

.trackName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown-menu img {
  width: 25px;
  margin: 2px 5px;
  height: 20px;
}

.dropdown-menu a {
  cursor: pointer;
}

.dropdown.scrDropdown {
  margin-left: 15px;
}

.dropdown.scrDropdown .btn.dropdown-toggle img {
  width: 20px;
  margin-right: 10px;
}

.topnav {
  display: flex;
  justify-content: flex-end;
}

.topnav-centered {
  float: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.topnav-right {
  padding-top: 15px;
}

.error-message {
  color: red;
}
