@media (max-width: 1023px) {
  .bannerWrap {
    height: 50vh;
  }
}

@media (max-width: 991px) {
  .container {
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .tab-content .imgWrapper,
  .tab-content .dataWrapper {
    text-align: center;
  }
  #nav-icon {
    display: block;
    right: 30px;
    top: 5px;
    position: absolute;
  }
  ul.navList {
    position: fixed;
    width: 80%;
    height: calc(100vh + 0px);
    top: 0;
    left: -100%;
    margin: 0;
    padding: 50px 0 20px;
    overflow: auto;
    background: #000000;
    z-index: 10;
    transition: all ease-in-out 0.3s;
    -webkit-transition: all ease-in-out 0.3s;
    flex-flow: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  ul.navList li {
    border-bottom: 1px dashed #8b8b8b;
    width: 100%;
    margin: 0;
    padding: 30px;
  }
  ul.navList li a {
    font-size: 1.6rem;
  }
  ul.navList.active {
    left: 0;
  }
}

@media (max-width: 650px) {
  .nav-tabs li a {
    min-width: 1px;
    padding: 6px 10px;
  }
  .nav-tabs li {
    margin: 0 6px !important;
  }
}

@media (max-width: 575px) {
  .nav-tabs {
    justify-content: flex-start;
    white-space: nowrap;
    overflow-x: auto;
    flex-flow: row;
  }
  .nav-tabs li {
    margin: 0 6px 15px !important;
    display: inline-block;
    float: none;
  }
}
